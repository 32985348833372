import axios from "axios";
import { getToken } from "./auth";
//const URL = 'localhost:4444'
//const baseURL = 'http://'+URL
const baseURL = 'https://vanilsaoliveira.herokuapp.com'
// const baseURL = '/'
// const baseURL = 'http://localhost:4444'
const api = axios.create({
    baseURL
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
export {baseURL};
export default api;
import React, { Component, useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import { isAdmin } from '../services/auth';
import EditorJS from '@editorjs/editorjs';
import Popover, { ArrowContainer } from 'react-tiny-popover'

import YouTube from 'react-youtube';

let editor,editor2,editor3,editor4;
function Sobre(props) {
    useEffect(() => {
        return () => {
            editor=editor2=editor3=editor4=null
        }
      }, [])
    const [editando, setEditando] = useState(isAdmin())
    let sobre = props.dadosSobreHook.dadosSobre;
    let setSobre = props.dadosSobreHook.setDadosSobre;
    console.log(sobre)
    var { aClass } = props;
    
    sobre.sobre1 && sobre.sobre1.dataEditor && !editor && isAdmin() && (editor = new EditorJS({
        holderId: 'editorSobre1',
        ...props.editorTools,
        data: { blocks: sobre.sobre1.dataEditor },
        minHeight: 0,
        onChange: () => { editor.save().then(d => setSobre(s=>({...s,sobre1:{...s.sobre1,dataEditor:d.blocks} })) ) }
      }))
      sobre.mentoringEmpresarial&&sobre.mentoringEmpresarial.dataEditor && !editor2 && isAdmin() && (editor2 = new EditorJS({
        holderId: 'editorMentoring',
        ...props.editorTools,
        data: { blocks: sobre.mentoringEmpresarial.dataEditor },
        minHeight: 0,
        onChange: () => { editor2.save().then(d => setSobre(s=>({...s,mentoringEmpresarial:{...s.mentoringEmpresarial,dataEditor:d.blocks} }))) }
    }))
    sobre.sobre2&&sobre.sobre2.dataEditor && !editor3 && isAdmin() && (editor3 = new EditorJS({
        holderId: 'editorSobre2',
        ...props.editorTools,
        data: { blocks: sobre.sobre2.dataEditor },
        minHeight: 0,
        onChange: () => { editor3.save().then(d =>  setSobre(s=>({...s,sobre2:{...s.sobre2,dataEditor:d.blocks} }))) }
      }))
      sobre.quoted&&sobre.quoted.dataEditor && !editor4 && isAdmin() && (editor4 = new EditorJS({
        holderId: 'editorQuoted',
        ...props.editorTools,
        data: { blocks: sobre.quoted.dataEditor },
        minHeight: 0,
        onChange: () => { editor4.save().then(d =>  setSobre(s=>({...s,quoted:{...s.quoted,dataEditor:d.blocks} }))) }
    }))
    console.log(editor)
    return (
        <section className={aClass} id="sobre">
            <div className="" style={{ margin: 0 }}>
                <div className="row">
                        <Fade bottom cascade duration={1000}>
                    <div className="col-md-6 d-flex" style={{ backgroundColor: '#f8f7fc' }}>
                            <div className="sobre-video">
                            <YouTube 
                                style={{width:'100%'}}
                                videoId="HuiDt0-cqRk"
                            />
                            </div>
                    </div>
                        </Fade>
                    <div className="col-md-6 d-flex"  id="sobre1PopoverDest">
                        <Fade bottom cascade duration={1000}>
                            <div className="sobre-conteudo"  id="editorSobre1" >
                                {!editando&&sobre.sobre1&&sobre.sobre1.dataEditor&&sobre.sobre1.dataEditor.map(block => {
                                    switch (block.type){
                                        case 'header':
                                            return <h2 dangerouslySetInnerHTML={{ __html: block.data.text }}></h2>
                                        case 'paragraph':
                                            return <p  dangerouslySetInnerHTML={{ __html: block.data.text }}></p>
                                        case 'raw':
                                            return <div dangerouslySetInnerHTML={{ __html: block.data.html }}></div>
                                    }
                                })}
                            </div>
                        </Fade>
                    </div>
                    <div className="col-md-6 d-flex" id="servicos">
                        <Fade bottom cascade duration={1000}>
                            <div className="sobre-conteudo"  id="editorMentoring">
                                {!editando&&<>
                                <h2>{sobre.mentoringEmpresarial&&sobre.mentoringEmpresarial.dataEditor&&sobre.mentoringEmpresarial.dataEditor[0].data.text}</h2>
                                <div className="row mentoring">

                                    <div className="col-6">
                                        <img className="img-sobre" src={require('../predio-icon.png')} alt="" />
                                    </div>
                                    <div className="col-6">
                                        <img className="img-sobre" src={require('../pessoas-icon.png')} alt="" />
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-6">
                                        <p>
                                        {sobre.mentoringEmpresarial&&sobre.mentoringEmpresarial.dataEditor&&sobre.mentoringEmpresarial.dataEditor[1].data.text}</p>
                                    </div>
                                    <div className="col-6">
                                        <p>
                                        {sobre.mentoringEmpresarial&&sobre.mentoringEmpresarial.dataEditor&&sobre.mentoringEmpresarial.dataEditor[2].data.text}</p>
                                    </div>

                                </div></>}
                            </div>
                        </Fade>
                    </div>
                    <div className="col-md-6 d-flex" style={{ padding: '0' }}>
                        <Fade bottom cascade duration={1000}>
                            <img className="img-iceberg" src={require('../fotos/o-que-é-Business-Analytics-por-que-está-crescendo.jpg')} alt="" />
                        </Fade>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <Fade bottom cascade duration={1000}>
                            <div className="sobre-conteudo" id="editorSobre2"  style={{ paddingBottom: '1rem' }}>
                                {!editando&&sobre.sobre2&&sobre.sobre2.dataEditor&&sobre.sobre2.dataEditor.map(block => {
                                    switch (block.type){
                                        case 'header':
                                            return <h2 dangerouslySetInnerHTML={{ __html: block.data.text }}></h2>
                                        case 'paragraph':
                                            return <p dangerouslySetInnerHTML={{ __html: block.data.text }}></p>
                                        case 'raw':
                                            return <div dangerouslySetInnerHTML={{ __html: block.data.html }}></div>
                                    }
                                })}
                            </div>
                        </Fade>
                    </div>

                </div>
            </div>
            <div style={{ margin: 0, maxWidth: 'initial', textAlign: 'left' }}>
                <div className="row"><Fade left cascade duration={500}>
                    <div className="col-xl-7 col-1">
                        <div className="about_img2">
                            <img src={require('../banner-vanilsa.jpg')} alt="" />
                        </div>
                    </div></Fade> <Fade right cascade duration={1000}>
                    <div className="col-xl-5 col-12 d-flex align-items-center about_col2">
                       
                            <div className="about_quotes">
                                {/* <h6>{jhonData.role}</h6> */}
                                <img className="about_quotes_quote1" src={require('../quote1.png')} alt="" />
                                <div id="editorQuoted">
                                    <i>
                                {!editando&&sobre.quoted&&sobre.quoted.dataEditor&&sobre.quoted.dataEditor.map(block => {
                                    switch (block.type){
                                        case 'header':
                                            return <h2 dangerouslySetInnerHTML={{ __html: block.data.text }}></h2>
                                        case 'paragraph':
                                            return <p dangerouslySetInnerHTML={{ __html: block.data.text }}></p>
                                        case 'raw':
                                            return <div dangerouslySetInnerHTML={{ __html: block.data.html }}></div>
                                    }
                                })}</i></div>
                                <img className="about_quotes_quote2" src={require('../quote2.png')} alt="" />
                                {/*Você está sendo protagonista do seu <span style='color: #4596ff'>sucesso</span>? */}
                                {/* <Link to="/"  className="theme_btn active">Hire Me</Link>
                                    <Link to="/" className="theme_btn">Download CV</Link> */}
                                {/* <a >SAIBA MAIS</a> */}
                            </div>
                        
                    </div></Fade>
                </div>
            </div>
        </section>
    )
}
export default Sobre;

import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import EditorJS from '@editorjs/editorjs';
import { isAdmin } from '../services/auth';
import EditorHeader from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import Embed from '@editorjs/embed';
import api from '../services/api';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
const editorTools = {
    tools: {
        header: {
            class: EditorHeader,
            inlineToolbar: ['link'],
            config: {
                placeholder: 'Header'
            },
            shortcut: 'CMD+SHIFT+H'
        },
        image: {
            class: SimpleImage,
            inlineToolbar: ['link'],
        },
        list: {
            class: List,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+L'
        },
        linkTool: LinkTool,
        raw: RawTool,
        embed: Embed,
    },

}
let editor

function Usuarios(props) {
    
    const [usuarios, setUsuarios] = useState([])
    useEffect(() => {
        getUsuarios();
    }, [])
    const [editUsuario, setEditUsuario] = useState(false)
    const [removeUsuario, setRemoveUsuario] = useState(false)
    const [categoria, setCategoria] = useState('')
    const [currUsuario, setCurrUsuario] = useState({
    })
    function getUsuarios() {
        api.get('/usuario').then(response => {
            console.log(response)
            response.data.usuarios.sort((a,b)=>
                a.permissao==b.permissao?0
                :a.permissao=='admin'?-1
                :b.permissao=='admin'?1
                :a.permissao=='aluno'&&b.permissao=='solicitacao'?-1
                :b.permissao=='aluno'&&a.permissao=='solicitacao'?1
                :new Date(a.createdDate)-new Date(b.createdDate))
            setUsuarios(response.data.usuarios)
        })
    }
      function editUsuarioPrompt (item) {
          setCurrUsuario(item)
          setEditUsuario(true)
      };
      function removeUsuarioPrompt (item) {
          setCurrUsuario(item)
          setRemoveUsuario(true)
      };
      
    function editUsuarioRequest() {
        console.log(currUsuario)
        api.post('/usuario/criar', currUsuario).then(response => {
            console.log(response)
            if (response.data.success) {
                setEditUsuario(false)
                getUsuarios();
            }
            //setTreinamentos(response.data)
        })
    }
    return (
        <section id="usuario">
            <div className="container-treinamento container-usuario" >
            {isAdmin() && removeUsuario && 
                            <SweetAlert title={'Remover usuario?'}
                                        onConfirm={()=>{
                                            api.delete('/usuario/'+currUsuario._id).then(response => {
                                                console.log(response)
                                                setRemoveUsuario(false)
                                                setUsuarios(response.data.usuario)
                                            })
                                        }}
                                        onCancel={()=>setRemoveUsuario(false)}
                                        style={{width:'70em'}}>
                                        Nome: {currUsuario.nome}
                                        Email: {currUsuario.email}
                                </SweetAlert>}
                {isAdmin() && editUsuario && 
                            <SweetAlert title={currUsuario.permissao=='solicitacao'?'Aprovar solicitação':'Editar usuário'}
                                        onConfirm={editUsuarioRequest}
                                        onCancel={()=>setEditUsuario(false)}
                                        style={{width:'70em'}}
                                        confirmBtnText={currUsuario.permissao=='solicitacao'?'Aprovar':'Salvar'}>
                                    <div className="row form-treinamento form-usuario form-group" style={{ textAlign: 'left' }}>
                                        <div className="col-lg-6">
                                            <label>Nome</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.nome} 
                                                onChange={(e)=>{let nome=e.target.value;setCurrUsuario(c=>({...c,nome}))}}/>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Email</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.email} 
                                                onChange={(e)=>{let email=e.target.value;setCurrUsuario(c=>({...c,email}))}}/>
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Data de Nascimento</label>
                                            <input className="form-control" type="date" 
                                                value={currUsuario.dataNascimento} 
                                                onChange={(e)=>{let dataNascimento=e.target.value;setCurrUsuario(c=>({...c,dataNascimento}))}}/>
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Escolaridade</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.escolaridade} 
                                                onChange={(e)=>{let escolaridade=e.target.value;setCurrUsuario(c=>({...c,escolaridade}))}}/>
                                        </div>
                                        
                                        <div className="col-lg-3">
                                            <label>CPF</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.cpf} 
                                                onChange={(e)=>{let cpf=e.target.value;setCurrUsuario(c=>({...c,cpf}))}}/>
                                        </div>
                                        
                                        <div className="col-lg-3">
                                            <label>RG</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.rg} 
                                                onChange={(e)=>{let rg=e.target.value;setCurrUsuario(c=>({...c,rg}))}}/>
                                        </div>
                                        
                                        <div className="col-lg-3">
                                            <label>CEP</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.cep} 
                                                onChange={(e)=>{let cep=e.target.value;setCurrUsuario(c=>({...c,cep}))}}/>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Cidade</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.cidade} 
                                                onChange={(e)=>{let cidade=e.target.value;setCurrUsuario(c=>({...c,cidade}))}}/>
                                        </div>
                                        <div className="col-lg-3">
                                            <label>Estado</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.estado} 
                                                onChange={(e)=>{let estado=e.target.value;setCurrUsuario(c=>({...c,estado}))}}/>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Endereço</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.endereco} 
                                                onChange={(e)=>{let endereco=e.target.value;setCurrUsuario(c=>({...c,endereco}))}}/>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Bairro</label>
                                            <input className="form-control" type="text" 
                                                value={currUsuario.bairro} 
                                                onChange={(e)=>{let bairro=e.target.value;setCurrUsuario(c=>({...c,bairro}))}}/>
                                        </div>
                                        {currUsuario.permissao!='solicitacao'&&
                                            <div className="col-lg-12">
                                            <label>Senha</label>
                                            <input className="form-control" type="password"  autoComplete='new-password'
                                                value={currUsuario.password} 
                                                onChange={(e)=>{let password=e.target.value;setCurrUsuario(c=>({...c,password}))}}/>
                                            </div>}
                                        
                                        
                                    </div>
                                </SweetAlert>}
                <div className="row">
                    <div className="col-sm-9 ">
                        
                            <Fade right mirror cascade duration={1000}>
                        <ul className="treinamentos-list usuarios-list">
                        {usuarios && <>
                        <li className="treinamento-item usuario-item" style={{justifyContent:'center', textAlign:'left'}}>
                            <h3>{categoria?categoria=='solicitacao'?'Solicitação':categoria=='admin'?'Administradores':categoria=='aluno'?'Alunos':categoria:'Usuários'}</h3>
                            </li>
                        {usuarios.filter(e=>categoria?e.permissao==categoria:true).map(item =>
                                    <li className="treinamento-item usuario-item" style={{backgroundColor:item.permissao=='admin'?'#aef4ff':item.permissao=='aluno'?'#c9ffe1':item.permissao=='solicitacao'?'#ffcccc':''}}>
                                            <span style={{padding:'0 2rem',cursor:'pointer'}}>
                                            <h4 className="treinamento-titulo usuario-title">{item.nome}</h4>
                                            <h6 className="treinamento-categoria usuario-categoria">{item.email}</h6>
                                            <h6 className="treinamento-categoria usuario-categoria">{item.permissao}</h6></span>
                                            <div style={{marginLeft:'auto'}}>
                                                <i className="fa fa-trash pull-right treinamento-buttons usuario-buttons" onClick={()=>removeUsuarioPrompt(item)}></i>
                                                <i className="fa fa-edit pull-right treinamento-buttons usuario-buttons" onClick={()=>editUsuarioPrompt(item)}></i>
                                                <i className="fa fa-eye pull-right treinamento-buttons usuario-buttons"  /*onClick={()=>viewUsuario(item)}*/></i>
                                                <h6 className="treinamento-categoria usuario-categoria">{new Date(item.createdDate).toLocaleDateString()}</h6>
                                            </div>
                                            
                                    </li>
                            )}</>}</ul></Fade>
                    </div>
                    <div className="col-sm-3">
                    <div className="card card-default">
                    <div className="card-header"><h4>Categorias</h4></div>
                    <div className="card-body">
                        <ul className="list-group itensLessons">
                            {usuarios&&[...new Set(usuarios.map(item => item.permissao))].map(cat=>
                            <li className={`treinamento-item usuario-item ${categoria==cat?'categoria-active':''}`}
                            style={{cursor:'pointer',justifyContent: 'space-between'}}
                            onClick={()=>setCategoria(categoria==cat?'':cat)}>
                                <a className="nav-link">
                                    <i className={`fa ${categoria==cat?'fa-times':'fa-bars'}`}></i> {cat}
                                </a>
                                {usuarios.filter(a=>a.permissao==cat).length}
                            </li>)}
                        </ul>

                    </div>
                </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Usuarios;

import React, { Component } from "react";

import { logout } from "../services/auth";
class Logout extends Component {
  constructor(props){
    super(props)
    logout()
    props.history.push("/");

  }
  render() {
    return (
      <></>
    );
  }
}

export default Logout;
import React, { useState } from "react";
import { withRouter, useHistory, useLocation, Link } from "react-router-dom";
import api from "../services/api";
import { login } from "../services/auth";


function Login() {
  const [userData,setUserData] =useState({
    email: "",
    password: "",
    error: ""
  });
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  const handleLogin = async e => {
    e.preventDefault();
    const { email, password } = userData;
    if (!email || !password) {
      setUserData(s=>({...s, error: "Preencha e-mail e senha para continuar!" }));
    } else {
      try {
        const response = await api.post("/usuario/login", { email, password });
        login(response.data.token);
        history.replace(from);
      } catch (err) {
        setUserData(s=>({...s,
          error:
            "Houve um problema com o login, verifique suas credenciais."
        }));
      }
    }
  };

    return (
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin}>
          <img src={require('../logo-vanilsa-azul-1.png')} alt="Vanilsa Logo" />
          {userData.error && <p>{userData.error}</p>}
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={e => {let email=e.target.value; setUserData(s=>({...s, email}))}}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={e => {let password=e.target.value; setUserData(s=>({...s, password }))}}
          />
          <Link className="nav-link" to="/cadastro">Não possui acesso? Solicite um cadastro!</Link>
          <button type="submit">Entrar</button>
          <hr />
        </form>
      </div>
    );
}

export default withRouter(Login);
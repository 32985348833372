import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import EditorJS from '@editorjs/editorjs';
import { isAdmin } from '../services/auth';
import EditorHeader from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import Embed from '@editorjs/embed';
import api from '../services/api';
import { useParams, useHistory } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const editorTools = {
    tools: {
        header: {
            class: EditorHeader,
            inlineToolbar: ['link'],
            config: {
                placeholder: 'Header'
            },
            shortcut: 'CMD+SHIFT+H'
        },
        image: {
            class: SimpleImage,
            inlineToolbar: ['link'],
        },
        list: {
            class: List,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+L'
        },
        linkTool: LinkTool,
        raw: RawTool,
        embed: Embed,
    },

}
let editor
function ViewTreinamento(props) {
    const history = useHistory();
    let { slug } = useParams()
    const [post, setPost] = useState({})
    const [addPostagem, setAddPostagem] = useState(false)
    
    console.log(slug)
    useEffect(() => {
            api.get('/treinamentos/' + slug).then(response => {
                console.log(response)
                setPost(response.data.treinamento)
            })
    }, [])
    return (
        <section id="treinamento">
            <div className="container-treinamento" >
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        {post && post.createdDate &&
                            <Fade bottom cascade duration={1000}>
                                <div className="treinamento-post">
                                    <div className="treinamento-post-header">
                                        <div className="data-post">{new Date(post.createdDate).getDate()} <br /> {new Date(post.createdDate).toLocaleString('pt', { month: 'long' }).substr(0, 3).toUpperCase()}</div>
                                        <h2 className="treinamento-title">{post.titulo}</h2>
                                    </div>
                                    <h5 className="treinamento-por-user" style={{ display: 'inline-block' }}>{post.categoria}</h5>
                                    <img className="capa-post" src={post.srcCapaPost} alt="" />

                                    
                                    <Editor
                                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(post.textoEditorContent)))}
                                        wrapperClassName="editor-wrapper"
                                        editorClassName="demo-editor"
                                        readOnly={true}
                                        toolbarHidden={true}
                                    />
                                </div>
                            </Fade>}
                        
                        { post.questoesEditorContent && 
                            <Fade bottom cascade duration={1000}>
                            <h3>Questionário</h3>
                      <ol>
                      {  post.questoesEditorContent.map((questao,i)=>
                                <li className="treinamento-questionario" >
                                    <Editor
                                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(questao)))}
                                        wrapperClassName="editor-wrapper"
                                        editorClassName="demo-editor"
                                        readOnly={true}
                                        toolbarHidden={true}
                                    />
                                    <input className="form-control" type="text" name={"resposta-"+i}></input>
                                </li>
                        )}</ol>

                        <button type="submit" className="input-button">Enviar respostas</button>
                            </Fade>}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ViewTreinamento;

import Header from './Header'
import Banner from './Banner'
import Posts from './Posts'
import Sobre from './Sobre'
import Footer from './Footer'
import React, { useState, useEffect } from 'react'
import api from './../services/api'
import EditorHeader from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import Embed from '@editorjs/embed';
import Depoimentos from './Depoimentos'
import Blog from "./Blog";
import BlogPost from "./BlogPost";
import Chat from "./Chat";
import Noticias from "./Noticias";
import { Route } from 'react-router-dom'
import { PrivateRoute } from '../routes'
import Contato from './Contato'
import Treinamento from './Treinamento'
import Usuarios from './Usuarios'
import NoticiaPost from './NoticiaPost'
import ViewTreinamento from './ViewTreinamento'


const editorTools = {
  tools: {
    header: {
      class: EditorHeader,
      inlineToolbar: ['link'],
      config: {
        placeholder: 'Header'
      },
      shortcut: 'CMD+SHIFT+H'
    },
    image: {
      class: SimpleImage,
      inlineToolbar: ['link'],
    },
    list: {
      class: List,
      inlineToolbar: true,
      shortcut: 'CMD+SHIFT+L'
    },
    linkTool: LinkTool,
    raw: RawTool,
    embed: Embed,
  },

}
function Home(props) {


  const [dadosBanner, setDadosBanner] = useState({})
  const [dadosSobre, setDadosSobre] = useState({})
  const [dadosFooter, setDadosFooter] = useState({})
  const [posts, setPosts] = useState([])
  useEffect(() => {
    api.get('/home').then(response => {
      console.log(response)
      setDadosBanner(response.data.textosHome.banner)
      setDadosSobre(response.data.textosHome.sobre)
      setDadosFooter(response.data.textosHome.footer)
    })
    api.get('/posts').then(response => {
      setPosts(response.data.posts)
    })
  }, [])
  return (
    <div className="App">

      <div className="App">
        <Chat />
        <Header blog={false} dadosBanner={dadosBanner} dadosSobre={dadosSobre} />
        <Route path="/blog/:slug"><BlogPost /></Route>
        <Route exact path="/blog"><Blog /></Route>
        <Route path="/noticias/:slug"><NoticiaPost /></Route>
        <Route exact path="/noticias"><Noticias /></Route>
        <Route path="/contato"><Contato /></Route>
        <PrivateRoute path="/treinamento/:slug"><ViewTreinamento /></PrivateRoute>
        <PrivateRoute exact path="/treinamento" component={Treinamento}></PrivateRoute>
        <PrivateRoute path="/usuarios" component={Usuarios} />
        <Route exact path="/" component={() => <>
          <Banner aClass='about_area' editorTools={editorTools} dadosBannerHook={{ dadosBanner, setDadosBanner }} />
          <Sobre aClass='sobre_area' editorTools={editorTools} dadosSobreHook={{ dadosSobre, setDadosSobre }} />
          <Posts aClass='posts_area' editorTools={editorTools} posts={posts} />
          <Depoimentos aClass='depoimentos_area' /></>} />
        <Footer aClass='footer2_area' editorTools={editorTools} dadosFooterHook={{ dadosFooter, setDadosFooter }} />
      </div>                                                                                                 
    </div>)
}
export default Home

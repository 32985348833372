import React, { useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import api from "../services/api";
import { login } from "../services/auth";


function Cadastro() {
  const [userData, setUserData] = useState({
    nome: "",
    email: "",
    password: "",
    foto: "",
    cpf: "",
    rg: "",
    cep: "",
    cidade: "",
    estado: "",
    rua: "",
    bairro: "",
    dataNascimento: "",
    escolaridade: ""
  });
  const [error, setError] = useState('');
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  const handleCadastro = async e => {
    e.preventDefault();
    if (userData.confirmPassword != userData.password) {
      setError('Senhas não conferem')
      return
    }
    setError('')
    if (!userData.email || !userData.password || !userData.nome) {
      setError("Preencha e-mail e senha para continuar!");
    } else {
      try {
        const response = await api.post("/usuario/solicitar", userData);
        console.log(response)
        //login(response.data.token);
        //history.replace(from);
      } catch (err) {
        setError("Houve um problema com o login, verifique suas credenciais.");
      }
    }
  };

  function handleImgInputChange(evt) {
    var tgt = evt.target || window.event.srcElement,
      files = tgt.files;

    if (files && files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        setUserData(p => ({ ...p, foto: fr.result }))
      }
      fr.readAsDataURL(files[0]);
    }
    else setUserData(p => ({ ...p, foto: '' }))
  }
  return (
    <div className="login-container">
      <form className="login-form cadastro-form" onSubmit={handleCadastro} >
        <img src={require('../logo-vanilsa-azul-1.png')} alt="Vanilsa Logo" />
        {error && <p>{error}</p>}
        {userData.foto && <img className="ppimg" src={userData.foto} onClick={() => {document.getElementById('fpSelect').value=""; setUserData(s => ({ ...s, foto: "" }))}} style={{ height: '150px', width: '150px',
    marginBottom: '15px' }} />}
        {userData.foto && "Clique na imagem para remover"}


        <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); document.getElementById('fpSelect').click() }}>
          {userData.foto ? 'Alterar foto de perfil' : 'Escolher foto de perfil'}
        </button>
        <input id="fpSelect" type="file" hidden onChange={handleImgInputChange} />
        <div className="row" style={{width: "100%"}}>

            <div className="col-lg-6">

              <input
                placeholder="Nome"
                onChange={e => { let nome = e.target.value; setUserData(s => ({ ...s, nome })) }}
              />
            </div>
            <div className="col-lg-6">

              <input
                type="email"
                placeholder="Endereço de e-mail"
                onChange={e => { let email = e.target.value; setUserData(s => ({ ...s, email })) }}
              />
            </div>
            <div className="col-lg-3">

              <input
              type="date"
                placeholder="Data de Nascimento dd/mm/aa"
                onChange={e => { let dataNascimento = e.target.value; setUserData(s => ({ ...s, dataNascimento })) }}
              />
            </div>
            <div className="col-lg-3">

              <input
                placeholder="Escolaridade"
                onChange={e => { let escolaridade = e.target.value; setUserData(s => ({ ...s, escolaridade })) }}
              />
            </div>

            <div className="col-lg-3">

              <input
                placeholder="CPF"
                onChange={e => { let cpf = e.target.value; setUserData(s => ({ ...s, cpf })) }}
              />
            </div>

            <div className="col-lg-3">

              <input
                placeholder="RG"
                onChange={e => { let rg = e.target.value; setUserData(s => ({ ...s, rg })) }}
              />
            </div>
            <div className="col-lg-3">

              <input
                placeholder="CEP"
                onChange={e => { let cep = e.target.value; setUserData(s => ({ ...s, cep })) }}
              />
            </div>


            <div className="col-lg-6">

              <input
                placeholder="Cidade"
                onChange={e => { let cidade = e.target.value; setUserData(s => ({ ...s, cidade })) }}
              />
            </div>
            <div className="col-lg-3">

              <input
                placeholder="Estado"
                onChange={e => { let estado = e.target.value; setUserData(s => ({ ...s, estado })) }}
              />
            </div>
            <div className="col-lg-6">

              <input
                placeholder="Endereço ex: Rua abc, 1000"
                onChange={e => { let endereco = e.target.value; setUserData(s => ({ ...s, endereco })) }}
              />
            </div>
            <div className="col-lg-6">

              <input
                placeholder="Bairro"
                onChange={e => { let bairro = e.target.value; setUserData(s => ({ ...s, bairro })) }}
              />
            </div>
            <div className="col-lg-6">

              <input
                type="password" autoComplete="new-password"
                placeholder="Senha"
                onChange={e => { let password = e.target.value; setUserData(s => ({ ...s, password })) }}
              />
            </div>

            <div className="col-lg-6">

              <input
                type="password" autoComplete="new-password"
                placeholder="Confirmar Senha"
                onChange={e => { let confirmPassword = e.target.value; setUserData(s => ({ ...s, confirmPassword })) }}
              />
            </div>
        </div>
        <button type="submit" className="btn btn-primary" style={{ marginTop: '15px' }} >Pedir cadastro</button>
        <hr />
      </form>
    </div>
  );
}

export default withRouter(Cadastro);
import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import { Link } from 'react-router-dom';
import { isAdmin } from '../services/auth';
import api from '../services/api';
function Noticias(props) {
    const [posts, setPosts] = useState([])
    useEffect(() => {
        api.get('/posts/noticias').then(response => {
            console.log(response)
            setPosts(response.data.posts)
        })
    }, [])
    return (
        <section id="noticias">
            <div className="container-noticias" >
                <div className="row">
                    <div className="col-xl-8 offset-xl-2 col-lg-12 offset-1">
                        
                        {isAdmin() &&
                        
                        <div className="nav-item" style={{ margin: '2vw' }}>
                            <Link className="nav-link" style={{ textAlign: 'center' }} to="/noticias/criar">+<br />Nova Postagem</Link>
                        </div>}
                            <div className="row">
                        {posts && posts.map(post =>
                            <Fade bottom cascade duration={1000}>
                                <div className="col-xl-4 col-lg-6 noticia-post">
                                    <img className="capa-post capa-post-mini" src={post.srcCapaPost} alt="" />
                                    <div className="noticia-post-header-mini">
                                        <div className="data-post-noticia">{new Date(post.createdDate).toLocaleDateString()} </div>
                                         <h2 className="noticia-title-mini">{post.titulo}</h2>
                                    </div>
                                       <div className="resumo-post" >
                                    {post.blocks.map(block => {
                                        switch (block.type) {
                                            case 'paragraph':
                                                return <p dangerouslySetInnerHTML={{ __html: block.data.text}} className="noticia-text"></p>
                                            case 'link':
                                                return <a dangerouslySetInnerHTML={{ __html: block.data.text }}></a>
                                        }
                                    })}</div>
                                    <div className="nav-item"><Link className="nav-link" to={'noticias/'+post.slug}>LEIA MAIS</Link></div>
                                </div>
                            </Fade>)}</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Noticias;

import React, { Component } from 'react';
import Sticky from 'react-stickynode';
import { Link } from 'react-scroll';

import { Link as RouterLink, withRouter } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { isAdmin, isAuthenticated } from '../services/auth';
import api from '../services/api';
class Header extends Component {
    constructor(props) {
        super(props);
        this.refNavbar = React.createRef();
        this.refLogoHeader = React.createRef();
        this.mrAuto = React.createRef();
    }
    render() {
        const handleStateChange = (status) => {
            // if (status.status === Sticky.STATUS_FIXED) {
            //     this.refLogoHeader.current.style.opacity = 1
            //     this.refLogoHeader.current.style.transform = 'translateX(0)'
            // }
            // if (status.status === Sticky.STATUS_ORIGINAL) {
            //     this.refLogoHeader.current.style.opacity = 0
            //     this.refLogoHeader.current.style.transform = 'translateX(-100px)'
            // }
        }
        const saveTexts = () => {
            const texts = [this.props.dadosSobre.sobre1,
            this.props.dadosSobre.mentoringEmpresarial,
            this.props.dadosSobre.sobre2,
            this.props.dadosSobre.quoted,
            this.props.dadosBanner]
            api.post('/home/criar', texts).then(r => console.log(r))

        }
        const blog = this.props.location.pathname.startsWith('/blog');
        const noticias = this.props.location.pathname=='/noticias';
        const treinamento = this.props.location.pathname=='/treinamento';
        const contato = this.props.location.pathname.startsWith('/contato');
        const home = this.props.location.pathname=='/'

        this.mrAuto = React.createRef();
        console.log(this.props);
        return (
            <div>
                {/* <div className="logo-redes">
                    <div className="logo" ref={this.refLogo} >
                        <img src={require('../logo-vanilsa-azul-1.png')} alt="" />
                    </div>
                 </div> */}
                <Sticky top={0} innerZ={999} activeClass="navbar_fixed" >
                    <Navbar collapseOnSelect expand="lg" bg="white" variant="light"  ref={this.refNavbar}
                        style={isAdmin() ?
                            {
                                borderStyle: 'dashed',
                                borderWidth: '0px 0px 2px 0px',
                                borderColor: '#1c3d68'
                            } : {}}>

                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <RouterLink className="nav-link" to="/"  className="logo-header transition-default" ref={this.refLogoHeader} style={{ 
                            position: 'fixed', 
                            top: '0', 
                            left: '0', 
                            zIndex: '10000',
                            maxWidth: '19%',
                            minWidth: '240px' }}>

                            <img src={require('../logo-vanilsa-azul-hor2.png')} alt="" />
                            {/* <h1>Vanilsa Oliveira</h1>
                  <h2>Mentoring Empresarial</h2> */}
                        </RouterLink>
                        <Navbar.Collapse id="responsive-navbar-nav" style={{ margin: 'auto' }}>
                            <Nav className="transition-default" ref={this.mrAuto} >
                                <Nav.Item>
                                    {blog||noticias||contato||treinamento
                                        ?<RouterLink className="nav-link" to="/">Sobre</RouterLink>
                                        :<Link className="nav-link" activeClass="active" to="sobre" spy={true} smooth={true} offset={0} duration={500}>Sobre</Link> }</Nav.Item>
                                <Nav.Item>
                                    {blog||noticias||contato||treinamento
                                        ?<RouterLink className="nav-link" to="/">Serviços</RouterLink>
                                        :<Link className="nav-link" activeClass="active" to="servicos" spy={true} smooth={true} offset={-86} duration={500}>Serviços</Link> }</Nav.Item>
                                <Nav.Item>
                                    {blog||noticias||contato||treinamento
                                        ?<RouterLink className="nav-link" to="/">Depoimentos</RouterLink>
                                        :<Link className="nav-link" activeClass="active" to="depoimentos" spy={true} smooth={true} offset={-86} duration={500}>Depoimentos</Link> }</Nav.Item>
                                <Nav.Item>
                                    {blog
                                        ?<Link className="nav-link" activeClass="active" to="blog" spy={true} smooth={true} offset={-86} duration={500}>Blog</Link>
                                        :<RouterLink className="nav-link" to="/blog">Blog</RouterLink>}</Nav.Item>
                                <Nav.Item>
                                    {<RouterLink className="nav-link" to="/noticias">Noticias</RouterLink>}</Nav.Item>
                                <Nav.Item>
                                    {treinamento
                                        ?<Link className="nav-link" activeClass="active" to="treinamento" spy={true} smooth={true} offset={-86} duration={500}>Treinamento</Link>
                                        :<RouterLink className="nav-link" to="/treinamento">Treinamento</RouterLink>}</Nav.Item>
                                <Nav.Item>
                                    {contato
                                        ?<Link className="nav-link" activeClass="active" to="contato" spy={true} smooth={true} offset={-86} duration={500}>Contato</Link>
                                        :<RouterLink className="nav-link" to="/contato">Contato</RouterLink>}</Nav.Item>
                                
                                {isAdmin() &&
                                <Nav.Item>
                                    <RouterLink className="nav-link" to="/usuarios">Usuários</RouterLink></Nav.Item>}
                                {isAuthenticated() &&
                                <Nav.Item>
                                    <RouterLink className="logout-btn" to="/logout">Logout</RouterLink></Nav.Item>}
                        <span style={{position: 'fixed', right: '0', zIndex: '10000',top:'23%'}} >
                            <a href="https://www.facebook.com/vanilsaoliveiracoaching" style={{margin:'0 3px'}}><img height='22' src={require('../redes-fb-azul.png')} alt=""/></a>
                            <a href="https://www.instagram.com/vanilsaoliveira" style={{margin:'0 3px'}}><img height='22' src={require('../redes-insta-azul.png')}  alt=""/></a>
                            <a href="https://www.youtube.com/vanilsaoliveira" style={{margin:'0 3px'}}><img height='22' src={require('../redes-yt-azul.png')}   alt=""/></a>
                            <a href="https://www.linkedin.com/in/vanilsaoliveira" style={{margin:'0 3px'}}><img height='22' src={require('../redes-linkedin-azul.png')}  alt=""/></a>
                            <span style={{display: 'block',fontSize: '15px',fontFamily:'"Lato Light", sans-serif'}} >
                                (48) 99985-2349
                            </span>
                        </span>
                            </Nav>
                        </Navbar.Collapse>

                        {/* <div style={{opacity:'0',width:'1px'}}></div> */}
                    </Navbar>
                    {isAdmin() && home &&
                        <div className="saveButton"><a style={{ marginTop: '0.5rem', padding: '0 1rem' }} onClick={saveTexts}>Salvar Alterações</a></div>}
                </Sticky>
                {/* <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <nav className={`navbar navbar-expand-sm navbar-light ${mClass}`}>
                    <div className="container custome_container">
                        <a className="navbar-brand logo_h" href="index.html"><img src={require('../logo.svg')} alt=""/><img src={require('../logo.svg')} alt=""/></a>
                        
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                            <ul className="nav navbar-nav m-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="sobre" spy={true} smooth={true} offset={0} duration={500}>Sobre</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="servicos" spy={true} smooth={true} offset={-86} duration={500}>
                                        Serviços
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="portifolio" spy={true} smooth={true} offset={-86} duration={500}>
                                        Portifolio
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="depoimentos" spy={true} smooth={true} offset={-86} duration={500}>
                                      Depoimentos
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="blog" spy={true} smooth={true} offset={-86} duration={500}>
                                        Blog
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="contato" spy={true} smooth={true} offset={-86} duration={500}>
                                        Contato
                                    </Link>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </nav>
            </Sticky> */}

            </div>
        );
    }
}

export default withRouter(Header);
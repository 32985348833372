import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import EditorJS from '@editorjs/editorjs';
import { isAdmin } from '../services/auth';
import EditorHeader from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import Embed from '@editorjs/embed';
import api from '../services/api';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
const editorTools = {
    tools: {
        header: {
            class: EditorHeader,
            inlineToolbar: ['link'],
            config: {
                placeholder: 'Header'
            },
            shortcut: 'CMD+SHIFT+H'
        },
        image: {
            class: SimpleImage,
            inlineToolbar: ['link'],
        },
        list: {
            class: List,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+L'
        },
        linkTool: LinkTool,
        raw: RawTool,
        embed: Embed,
    },

}
let editor

function Treinamento(props) {
    const [treinamentos, setTreinamentos] = useState([])
    useEffect(() => {
        getTreinamentos();
    }, [])
    const [addTreinamento, setAddTreinamento] = useState(false)
    const [removeTreinamento, setRemoveTreinamento] = useState(false)
    const [categoria, setCategoria] = useState('')
    const [toggleEdit, setToggleEdit] = useState(false)
    const [hasFormulario, setHasFormulario] = useState(false)
    const [questoes, setQuestoes] = useState([EditorState.createEmpty()])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [currTreinamento, setCurrTreinamento] = useState({
        titulo: '',
        categoria: '',
        textoEditorContent: '',
        questoesEditorContent: [],
        anexos: []
    })
    // !editor && addTreinamento && isAdmin() && (editor = new EditorJS({
    //     holderId: 'editorCurrTreinamento',
    //     ...editorTools,
    //     minHeight: 0,
    //     data: {
    //         blocks: [
    //             {
    //                 "type": "paragraph",
    //                 "data": {
    //                     "text": "Texto da Treinamento"
    //                 }
    //             }]
    //     },
    //     onChange: () => { editor.save().then(d => { setCurrTreinamento(p => ({ ...p, blocks: d.blocks })); console.log(d.blocks) }) }
    // }))
    function cadastrarTreinamento() {
        let treinamento = {
            ...currTreinamento,
            textoEditorContent:JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            questoesEditorContent:hasFormulario?questoes.map(q=>JSON.stringify(convertToRaw(q.getCurrentContent()))):[]
        }
        console.log(treinamento)
        api.post('/treinamentos/criar', treinamento).then(response => {
            console.log(response)
            if (response.data.success) {
                setAddTreinamento(false)
                getTreinamentos();
            }
            //setTreinamentos(response.data)
        })
    }
    function getTreinamentos() {
        api.get('/treinamentos').then(response => {
            console.log(response)
            setTreinamentos(response.data.treinamentos)
        })
    }
    function handleFilesInputChange(evt) {
        var tgt = evt.target || window.event.srcElement,
            files = tgt.files;

        if (files && files.length) {
            for(const file of files){
                var fr = new FileReader();
                fr.onload = function () {
                    setCurrTreinamento(p => ({ ...p, anexos: [...p.anexos,{nome:file.name,data:fr.result}] }))
                }
                fr.readAsDataURL(file);
            }
            
        }
        else setCurrTreinamento(p => ({ ...p, srcCapaTreinamento: '' }))
    }
    function onEditorStateChange (editorState) {
        setEditorState({
          editorState
        });
      };
      function editTreinamento (item) {
          setAddTreinamento(true)
          setCurrTreinamento(item)
          setEditorState(
              EditorState.createWithContent(convertFromRaw(JSON.parse(item.textoEditorContent)))
          );
          setHasFormulario(item.questoesEditorContent.length>0)
          setQuestoes(
            item.questoesEditorContent.length>0 ? item.questoesEditorContent.map(q=>EditorState.createWithContent(convertFromRaw(JSON.parse(q)))) : []
          );
      };
      function removeTreinamentoPrompt (item) {
          setCurrTreinamento(item)
          setRemoveTreinamento(true)
      };
    //   function viewTreinamento (item) {
    //     history.replaceState
    //   };
    return (
        <section id="treinamento">
            <div className="container-treinamento" >
            {isAdmin() && removeTreinamento && 
                            <SweetAlert title={'Remover treinamento?'}
                                        onConfirm={()=>{
                                            api.delete('/treinamentos/'+currTreinamento._id).then(response => {
                                                console.log(response)
                                                setRemoveTreinamento(false)
                                                setTreinamentos(response.data.treinamentos)
                                            })
                                        }}
                                        onCancel={()=>setRemoveTreinamento(false)}
                                        style={{width:'70em'}}>
                                        Nome: {currTreinamento.titulo}
                                        Categoria: {currTreinamento.categoria}
                                </SweetAlert>}
                {isAdmin() && addTreinamento && 
                            <SweetAlert title={'Adicionar Conteúdo'}
                                        onConfirm={cadastrarTreinamento}
                                        onCancel={()=>setAddTreinamento(false)}
                                        style={{width:'70em'}}>
                                    <div className="row form-treinamento form-group" style={{ textAlign: 'left' }}>
                                        <div className="col-12">
                                            <label>Titulo do Treinamento</label>
                                            <input className="form-control" type="text" 
                                                value={currTreinamento.titulo} 
                                                onChange={(e)=>{let titulo=e.target.value;setCurrTreinamento(c=>({...c,titulo}))}}/>
                                        </div>
                                        <div className="col-6">
                                            <label>Adicionar anexos</label>
                                            <input style={{height:'fit-content'}}  onChange={handleFilesInputChange}  className="form-control" type="file" multiple />
                                        </div>
                                        <div className="col-6">
                                            <label>Categoria</label>
                                            <input className="form-control" type="text" 
                                                value={currTreinamento.categoria} 
                                                onChange={(e)=>{let categoria=e.target.value;setCurrTreinamento(c=>({...c,categoria}))}}/>
                                        </div>
                                        <div className="col-12">
                                            <label>Texto</label>
                                            <Editor
                                                editorState={editorState}
                                                wrapperClassName="editor-wrapper form-control "
                                                editorClassName="demo-editor"
                                                onEditorStateChange={setEditorState}
                                            />
                                        </div>
                                        <div className="col-1">
                                            <label>Formulário?</label>
                                            <input className="form-control" type="checkbox" 
                                            checked={hasFormulario}
                                            onChange={e=>{console.log(e.target.checked);setHasFormulario(e.target.checked)}} />
                                        </div>
                                        {hasFormulario&&
                                            <div className="treinamento-formulario row col-11">
                                                {questoes.map((q,i)=><>
                                                    <div className="offset-md-1 col-md-10">
                                                        <label>Questão {i+1}</label>
                                                        <Editor
                                                            editorState={q}
                                                            wrapperClassName="editor-wrapper form-control "
                                                            editorClassName="demo-editor"
                                                            onEditorStateChange={(e)=>setQuestoes(qs=>qs.map((q,idx) => idx == i ?  e : q ))}
                                                            readOnly={toggleEdit}
                                                            toolbarHidden={toggleEdit}
                                                        />
                                                    </div>
                                                    {questoes.length>0&&
                                                        <div className="col-md-1 add-remove-treinamento" onClick={()=>setQuestoes(q=>q.filter((v,idx)=>i!=idx))}>
                                                            <button type="button" className="btn btn-danger">-</button>
                                                        </div>}
                                                </>)}
                                                <div className="col-12 add-remove-treinamento" onClick={()=>setQuestoes(q=>[...q,EditorState.createEmpty()])}>
                                                <button type="button" className="btn btn-success">+</button>
                                                </div>
                                                <button type="button" className="btn btn-success" onClick={()=>setToggleEdit(!toggleEdit)}>toggleEdit</button>
                                            </div>
                                        }
                                    </div>
                                </SweetAlert>}
                        
                <div className="row">
                    <div className="col-sm-9 ">
                        
                            <Fade right mirror cascade duration={1000}>
                        <ul className="treinamentos-list">
                        {treinamentos && <>
                        {isAdmin() && 
                            
                                    <li className="treinamento-item" style={{justifyContent:'center'}}>
                            <div className="nav-item" style={{width:'100%'}}
                            onClick={()=>{setCurrTreinamento({
                                titulo: '',
                                categoria: '',
                                textoEditorContent: '',
                                questoesEditorContent: [],
                                anexos: []
                            });setQuestoes([]);setHasFormulario(false);setAddTreinamento(true)}}
                            >
                                <a className="nav-link" style={{ textAlign: 'center' }} >+<br />Novo Treinamento</a>
                            </div>
                            </li>
                        }
                        {treinamentos.filter(e=>categoria?e.categoria==categoria:true).map(item =>
                                    <li className="treinamento-item">
                                            <span style={{padding:'0 2rem',cursor:'pointer'}}>
                                            <h4 className="treinamento-titulo">{item.titulo}</h4>
                                            <h6 className="treinamento-categoria">{item.categoria}</h6></span>
                                            <div style={{marginLeft:'auto'}}>
                                            {isAdmin() && <>
                                            <i className="fa fa-trash pull-right treinamento-buttons" onClick={()=>removeTreinamentoPrompt(item)}></i>
                                            <i className="fa fa-edit pull-right treinamento-buttons" onClick={()=>editTreinamento(item)}></i></>}
                                            <Link to={'/treinamento/'+item.slug}><i className="fa fa-eye pull-right treinamento-buttons" ></i></Link></div>
                                            
                                    </li>
                            )}</>}</ul></Fade>
                    </div>
                    <div className="col-sm-3">
                    <div className="card card-default">
                    <div className="card-header"><h4>Categorias</h4></div>
                    <div className="card-body">
                        <ul className="list-group itensLessons">
                            {treinamentos&&[...new Set(treinamentos.map(item => item.categoria))].map(cat=>
                            <li className={`treinamento-item ${categoria==cat?'categoria-active':''}`}
                            style={{cursor:'pointer'}}
                            onClick={()=>setCategoria(categoria==cat?'':cat)}>
                                <a className="nav-link">
                                    <i className={`fa ${categoria==cat?'fa-times':'fa-bars'}`}></i> {cat}
                                </a>
                            </li>)}
                        </ul>

                    </div>
                </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Treinamento;

import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import { isAdmin } from '../services/auth';
import api from '../services/api';
import { Link } from 'react-router-dom';
import BlogSidebar from './BlogSidebar';

function Blog(props) {
    const [posts, setPosts] = useState([])
    useEffect(() => {
        api.get('/posts/blog').then(response => {
            console.log(response)
            window.scrollTo(0,0)
            setPosts(response.data.posts)
        })
    }, [])
    return (
        <section id="blog">
            <div className="container-blog" >
                <div className="row">
                    <div className="col-md-9 ">
                        {isAdmin() &&
                        
                            <div className="nav-item" style={{ margin: '2vw' }}>
                                <Link className="nav-link" style={{ textAlign: 'center' }} to="blog/criar">+<br />Nova Postagem</Link>
                            </div>}

                        {posts && posts.map(post =>
                            <Fade bottom cascade duration={1000}>
                                <div className="blog-post">
                                    <div className="blog-post-header">
                                        <div className="data-post">{new Date(post.createdDate).getDate()} <br /> {new Date(post.createdDate).toLocaleString('pt', { month: 'long' }).substr(0, 3).toUpperCase()}</div>
                                        <h2 className="blog-title">{post.titulo}</h2>
                                    </div>
                                    <h5 className="blog-por-user" style={{ display: 'inline-block' }}>POR &nbsp;</h5><h5 className="blog-por-user" style={{ display: 'inline-block' }}>{post.autor}</h5>
                                    <img className="capa-post" src={post.srcCapaPost} alt="" />

                                    <div className="resumo-post">
                                    {post.blocks.map(block => {
                                        switch (block.type) {
                                            case 'paragraph':
                                                return <p dangerouslySetInnerHTML={{ __html: block.data.text}} className="blog-text"></p>
                                            case 'link':
                                                return <a dangerouslySetInnerHTML={{ __html: block.data.text }}></a>
                                        }
                                    })}
                                    </div>
                                    <div className="nav-item"><Link className="nav-link" to={'blog/'+post.slug}>LEIA MAIS</Link></div>
                                </div>
                            </Fade>)}
                    </div>
                    
                    <BlogSidebar/>
                </div>
            </div>
        </section>
    )
}
export default Blog;

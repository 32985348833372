import { withRouter } from "react-router";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import React, { useState, useEffect } from 'react'
import Login from "./components/Login";
import Cadastro from "./components/Cadastro";
import Logout from "./components/Logout";
import Home from "./components/Home";
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }
  />
);
const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};
const Routes = () => {
  return (
    <BrowserRouter>
    <Route component={ScrollToTop} />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/cadastro" component={Cadastro} />
        {/* <Route path="/cadastro" component={Cadastro} /> */}
        <Route path="/logout" component={Logout} />
        <PrivateRoute path="/editar" component={() => <h2>App</h2>} />
        <Route path="/" >
          <Home/>
          </Route>
      </Switch>
    </BrowserRouter>
  )
};
export {PrivateRoute}
export default Routes;

import React from 'react';
// import {Link} from 'react-router-dom';
import {Fade, Reveal} from 'react-reveal/';
import { Link } from 'react-router-dom';

function Posts({posts}) {
        console.log(posts)
        return(
            <section id="posts">
                <div className="" style={{margin:0}}>
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <Fade bottom cascade duration={1000}>
                                <div className="blog-preview posts-preview">
                                    <h2 className="t_color">Acompanhe o blog </h2>
                                    <div className="row" style={{marginTop:'2rem'}}>
                                        <div className="col-lg-3" style={{textAlign: 'center', marginBottom:'1rem'}}>
                                            <img src={require('../blog-icon.png')} alt=""/>   
                                        </div>
                                        <div className="col-lg-9">
                                        <ul className="preview-list">
                                            {posts.filter(i=>i.tipo=='blog').slice(0,4).map(post=><>
                                            <li className="preview-item">
                                                <h6 className="preview-date">{new Date(post.createdDate).getDate()}<br/>{new Date(post.createdDate).toLocaleString('pt', { month: 'long' }).substr(0, 3).toUpperCase()}</h6>
                                                <img src={post.srcCapaPost||require('../logo-vanilsa-azul-hor2.png')} className="preview-capa"/>
                                                <h5 className="preview-title">{post.titulo}</h5>
                                                
                                            </li>
                                            <li className="divider"></li></>)}
                                            {/* <li className="preview-item">
                                                <h6 className="preview-date">25<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">Aonde você quer chegar?</h5>
                                                
                                            </li>
                                            <li className="divider"></li>
                                            <li className="preview-item">
                                                <h6 className="preview-date">04<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">O caminho para chegar ao sucesso.</h5>
                                                
                                            </li>
                                            <li className="divider"></li>
                                            <li className="preview-item">
                                                <h6 className="preview-date">04<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">O caminho para chegar ao sucesso.</h5>
                                                
                                            </li>
                                            <li className="divider"></li>
                                            <li className="preview-item">
                                                <h6 className="preview-date">04<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">O caminho para chegar ao sucesso.</h5>
                                                
                                            </li> */}
                                        </ul>
                                        </div>
                                    </div>
                                    <Link to="/blog" style={{color:'#797979'}}><img src={require('../eye-whitebg.png')} width='20px' /><span style={{marginLeft:'1rem',letterSpacing: '1px',fontSize: '15px'}}>TODAS AS POSTAGENS</span></Link>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-md-6 d-flex bg-4">
                            <Fade bottom cascade duration={1000}>
                                <div className="news-preview posts-preview">
                                    <h2>Últimas notícias</h2>
                                    <div className="row" style={{marginTop:'2rem'}}>
                                        <div className="col-lg-3" style={{textAlign: 'center', marginBottom:'1rem'}}>
                                            <img src={require('../news-icon.png')} alt=""/>   
                                        </div>
                                        <div className="col-lg-9">
                                        <ul className="preview-list">
                                            {posts.filter(i=>i.tipo=='noticias').slice(0,4).map(post=><>
                                            <li className="preview-item">
                                                <h6 className="preview-date">{new Date(post.createdDate).getDate()}<br/>{new Date(post.createdDate).toLocaleString('pt', { month: 'long' }).substr(0, 3).toUpperCase()}</h6>
                                                <img src={post.srcCapaPost||require('../logo vanilsa branco2.png')} className="preview-capa"/>
                                                <h5 className="preview-title">{post.titulo}</h5>
                                                
                                            </li>
                                            <li className="divider"></li></>)}
                                            {/* <li className="preview-item">
                                                <h6 className="preview-date">25<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">Aonde você quer chegar?</h5>
                                                
                                            </li>
                                            <li className="divider divider-white"></li>
                                            <li className="preview-item">
                                                <h6 className="preview-date">04<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">O caminho para chegar ao sucesso.</h5>
                                                
                                            </li>
                                            <li className="divider divider-white"></li>
                                            <li className="preview-item">
                                                <h6 className="preview-date">04<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">O caminho para chegar ao sucesso.</h5>
                                                
                                            </li>
                                            <li className="divider divider-white"></li>
                                            <li className="preview-item">
                                                <h6 className="preview-date">04<br/>OUT</h6>
                                                <img src={require('../banner.jpg')} className="preview-capa"/>
                                                <h5 className="preview-title">O caminho para chegar ao sucesso.</h5>
                                                
                                            </li> */}
                                        </ul>
                                        </div>
                                    </div>
                                    
                                    <Link to="/noticias" style={{color:'white'}}><img src={require('../eye-bluebg.png')} width='20px' /><span style={{marginLeft:'1rem',letterSpacing: '1px',fontSize: '15px'}}>TODAS AS NOTÍCIAS</span></Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        )
}
export default Posts;

import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import {Fade, Reveal} from 'react-reveal/';
import { isAdmin } from '../services/auth';

class Footer extends Component {
    render(){
        var {aClass} = this.props;

        return(
            <section className={`${aClass}`} id="footer">
                <div className="container" style={{margin:0,maxWidth: 'initial'}}>
                    <div className="row bg-1">
                        <div className="col-lg-3 col-6 d-flex align-items-center">
                            <Fade bottom cascade duration={1000}>
                                <div className="footer_content">
                                    <h2 style={{fontWeight: 'bold'}}  >Vanilsa Oliveira</h2>
                                    
                                    <h2 >
                                        9051 Mira Mesa Blvd PO Box
                                        261229São Diego , CA 92196
                                    </h2>
                                    <br/>
                                    <h2 style={{fontWeight: 'bold'}}>CONECTE-SE COM VANILSA</h2>
                            <a href="https://www.facebook.com/vanilsaoliveiracoaching" style={{margin:'0 3px'}}><img height='22' src={require('../redes-fb-branco.png')} alt=""/></a>
                            <a href="https://www.instagram.com/vanilsaoliveira" style={{margin:'0 3px'}}><img height='22' src={require('../redes-insta-branco.png')}  alt=""/></a>
                            <a href="https://www.youtube.com/vanilsaoliveira" style={{margin:'0 3px'}}><img height='22' src={require('../redes-yt-branco.png')}   alt=""/></a>
                            <a href="https://www.linkedin.com/in/vanilsaoliveira" style={{margin:'0 3px'}}><img height='22' src={require('../redes-linkedin-branco.png')}  alt=""/></a>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-3 col-6 d-flex">
                            <Fade bottom cascade duration={1000}>
                                <div className="footer_content">
                                    <h2  style={{fontWeight: 'bold'}}>SOBRE</h2>
                                    <h2  style={{fontWeight: 'bold'}}>QUEM É VANILSA OLIVEIRA?</h2>
                                    <h2  style={{fontWeight: 'bold'}}>MAPA DO SITE</h2>
                                    <h2  style={{fontWeight: 'bold'}}>CONTATO</h2>
                                    <h2  style={{fontWeight: 'bold'}}>BLOG</h2>
                                    
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-3 col-6 d-flex">
                            <Fade bottom cascade duration={1000}>
                                <div className="footer_content">
                                    <h2 style={{fontWeight: 'bold',color: '#4596ff'}} >SUPORTE AO CLIENTE</h2>
                                    
                                    <p >
                                        Entre em contato com o Suporte ao
                                        cliente para obter perguntas sobre
                                        seus produtos, treinamento ou
                                        eventos ....
                                    </p>
                                    
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-3 col-6 d-flex">
                            <Fade bottom cascade duration={1000}>
                                <div className="footer_content">
                                    <h2 style={{fontWeight: 'bold',color: '#4596ff'}}  >MENTORING EMPRESARIAL</h2>
                                    
                                    <p >
                                        Entre em contato com o Suporte ao
                                        cliente para obter perguntas sobre
                                        seus produtos, treinamento ou
                                        eventos ....
                                    </p>
                                    
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
                    <div className="footer-copyright">
                        <small >© 2019 Vanilsa Oliveira . Todos os direitos reservados</small></div>
            </section>
        )
    }
}
export default Footer;

import React, { Component, useState } from 'react';
// import {Link} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel'
import EditorJS from '@editorjs/editorjs';
import { isAdmin } from '../services/auth';

function Depoimentos(props) {
    var { aClass } = props;
    return (
        <section className={`${aClass}`} id="depoimentos">
            <Carousel id="fade-quote-carousel" slide={false} interval={5000}>
                <Carousel.Item>
                    <img  className="profile-circle" src={require('../fotos/6b3c1d45-b833-4976-91f4-535e4cf8fcae.jpeg')} />
                    <blockquote>
                        <h3>Karen Suyan</h3>
                        <h6>Comunicadora</h6>
                        <i>“A Vanilsa Oliveira é uma das profissionais mais talentosas eficientes que conheço. Fazer o Treinamento 90 dias foi fantástico, um divisor de águas na minha carreira.”</i>
                    </blockquote>
                </Carousel.Item>
                <Carousel.Item>
                    <img  className="profile-circle" src={require('../fotos/d4ebe369-b515-4ca8-8ea7-2407a8aa22bc.jpeg')} />

                    <blockquote>
                        <h3>Jaqueline Klein Simionatto</h3>
                        <h6>Psicóloga</h6>
                        <i>“Fiquei realmente satisfeita. É um investimento pequeno para o retorno que temos.”</i>
                    </blockquote>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}
export default Depoimentos;

import React, {Component,useState} from 'react';
// import {Link} from 'react-router-dom';
import {Fade, Reveal} from 'react-reveal/';
import EditorJS from '@editorjs/editorjs';
import { isAdmin } from '../services/auth';

let editor;
function Banner (props) {
        const [editando,setEditando]=useState(isAdmin())
        let banner = props.dadosBannerHook.dadosBanner; 
        let setBanner = props.dadosBannerHook.setDadosBanner
        console.log(banner.dataEditor)
        banner.dataEditor && !editor && isAdmin() && (editor = new EditorJS({
          holderId:'editorBanner',
            ...props.editorTools,
            data:{blocks:banner.dataEditor },
            minHeight: 0,
            onChange: () => { editor.save().then(d => setBanner(s=>({...s,dataEditor:d.blocks} )) ) }
        }))
        var {aClass} = props;
        return(
            <section className={`${aClass}`} id="banner">
                <div className="container" style={{margin:0,maxWidth: 'initial'}}>
                    <div className="row">
                        <div className="col-5 d-flex align-items-center about_col">
                                <div className="about_content" id="editorBanner">
                                    {!editando && banner.dataEditor&&banner.dataEditor.map(block => {
                                        switch (block.type){
                                            case 'header':
                                                return <h2 dangerouslySetInnerHTML={{ __html: block.data.html }}></h2>
                                            case 'paragraph':
                                                return <p dangerouslySetInnerHTML={{ __html: block.data.html }}></p>
                                            case 'raw':
                                                return <div dangerouslySetInnerHTML={{ __html: block.data.html }}></div>
                                        }
                                    })}
                                </div>
                        </div>
                        <div className="col-7">
                            <div className="about_img">
                                <Reveal effect="fadeInRight" duration={1500}><img src={require('../banner.jpeg')} alt=""/></Reveal>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
}
export default Banner;

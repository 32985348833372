import jwt from 'jwt-decode'

export const TOKEN_KEY = "token";
export const getUser=()=>jwt(getToken().replace('Bearer ',''));
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const isAdmin = () => {
  const token = getToken()
  if(token !== null){
    return jwt(token.replace('Bearer ','')).permissao=='admin'
  }
  return false;

}
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
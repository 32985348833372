import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import EditorJS from '@editorjs/editorjs';
import { isAdmin } from '../services/auth';
import EditorHeader from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import LinkTool from '@editorjs/link';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import Embed from '@editorjs/embed';
import api from '../services/api';
import { useParams, useHistory } from 'react-router-dom';

const editorTools = {
    tools: {
        header: {
            class: EditorHeader,
            inlineToolbar: ['link'],
            config: {
                placeholder: 'Header'
            },
            shortcut: 'CMD+SHIFT+H'
        },
        image: {
            class: SimpleImage,
            inlineToolbar: ['link'],
        },
        list: {
            class: List,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+L'
        },
        linkTool: LinkTool,
        raw: RawTool,
        embed: Embed,
    },

}
let editor
function NoticiaPost(props) {
    const history = useHistory();
    let { slug } = useParams()
    const [post, setPost] = useState({})
    const [addPostagem, setAddPostagem] = useState(false)
    console.log(slug)
    useEffect(() => {
        if (slug != 'criar')
            api.get('/posts/noticias/' + slug).then(response => {
                console.log(response)
                setPost(response.data.post)
            })
        else {
            if (isAdmin())
                setAddPostagem(true)
        }
    }, [])
    const [newPost, setNewPost] = useState({
        titulo: 'Titulo',
        autor: 'VANILSA OLIVEIRA',
        blocks: [
            {
                "type": "paragraph",
                "data": {
                    "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac ultricies felis. Curabitur sollicitudin nisl sed lacus mollis suscipit a id velit. Sed sit amet odio sodales, tincidunt ante nec, venenatis nulla. Morbi ut libero velit. Aliquam nec nulla id ante tempus lobortis. Suspendisse vitae elit vehicula, cursus orci a, tincidunt sapien. Morbi varius arcu a lorem blandit, sed elementum elit pretium. Donec ultricies ipsum ex, et malesuada tortor bibendum sit amet. Pellentesque mollis auctor laoreet."
                }
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "Sed ac augue quis eros ornare tincidunt et non enim. Nulla faucibus orci ut sem aliquet, vel iaculis libero blandit. Suspendisse sodales ligula sed magna venenatis faucibus. Quisque facilisis nulla id massa elementum, sit amet malesuada felis finibus. Cras non maximus augue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut auctor lectus nec nulla pharetra commodo. Aenean tortor nunc, fringilla sit amet commodo at, volutpat non erat. Mauris a gravida justo. Donec accumsan nisi a arcu porttitor, in vulputate velit posuere. Praesent in tempus nisi, vel pharetra tellus. Fusce pretium, orci ac tristique elementum, augue dolor porta urna, at consequat est dui ac lacus. Nullam cursus enim elit, imperdiet elementum lorem egestas in. Etiam at nisl fringilla, vehicula dui dignissim, tincidunt nulla."
                }
            }],
        srcCapaPost: ''
    })
    !editor && addPostagem && isAdmin() && (editor = new EditorJS({
        holderId: 'editorNewPost',
        ...editorTools,
        minHeight: 0,
        data: {
            blocks: [
                {
                    "type": "paragraph",
                    "data": {
                        "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ac ultricies felis. Curabitur sollicitudin nisl sed lacus mollis suscipit a id velit. Sed sit amet odio sodales, tincidunt ante nec, venenatis nulla. Morbi ut libero velit. Aliquam nec nulla id ante tempus lobortis. Suspendisse vitae elit vehicula, cursus orci a, tincidunt sapien. Morbi varius arcu a lorem blandit, sed elementum elit pretium. Donec ultricies ipsum ex, et malesuada tortor bibendum sit amet. Pellentesque mollis auctor laoreet."
                    }
                },
                {
                    "type": "paragraph",
                    "data": {
                        "text": "Sed ac augue quis eros ornare tincidunt et non enim. Nulla faucibus orci ut sem aliquet, vel iaculis libero blandit. Suspendisse sodales ligula sed magna venenatis faucibus. Quisque facilisis nulla id massa elementum, sit amet malesuada felis finibus. Cras non maximus augue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut auctor lectus nec nulla pharetra commodo. Aenean tortor nunc, fringilla sit amet commodo at, volutpat non erat. Mauris a gravida justo. Donec accumsan nisi a arcu porttitor, in vulputate velit posuere. Praesent in tempus nisi, vel pharetra tellus. Fusce pretium, orci ac tristique elementum, augue dolor porta urna, at consequat est dui ac lacus. Nullam cursus enim elit, imperdiet elementum lorem egestas in. Etiam at nisl fringilla, vehicula dui dignissim, tincidunt nulla."
                    }
                }]
        },
        onChange: () => { editor.save().then(d => { setNewPost(p => ({ ...p, blocks: d.blocks })); console.log(d.blocks) }) }
    }))
    function cadastrarPost() {
        console.log(newPost)
        api.post('/posts/criar/noticias', newPost).then(response => {
            console.log(response)
            if (response.data.success) {
                setAddPostagem(false)
                history.replace('/noticias');
            }
        })
    }
    function handleImgInputChange(evt) {
        var tgt = evt.target || window.event.srcElement,
            files = tgt.files;

        if (files && files.length) {
            var fr = new FileReader();
            fr.onload = function () {
                setNewPost(p => ({ ...p, srcCapaPost: fr.result }))
                console.log(fr.result)
            }
            fr.readAsDataURL(files[0]);
        }
        else setNewPost(p => ({ ...p, srcCapaPost: '' }))
    }
    return (
        <section id="noticia">
            <div className="container-noticia" >
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        {addPostagem &&
                            <div className="novaPostagem">
                                <div className="noticia-post">
                                    <div className="noticia-post-header">
                                        <div className="data-post">{new Date().getDate()} <br /> {new Date().toLocaleString('pt', { month: 'long' }).substr(0, 3).toUpperCase()}</div>
                                        <h2 className="noticia-title" contentEditable={true} suppressContentEditableWarning={true} onBlur={e => { const titulo = e.target.innerText; setNewPost(p => ({ ...p, titulo: titulo })) }}>{newPost.titulo}</h2>
                                    </div>
                                    <h5 className="noticia-por-user" style={{ display: 'inline-block' }}>POR &nbsp;</h5><h5 className="noticia-por-user" style={{ display: 'inline-block' }} contentEditable={true} suppressContentEditableWarning={true} onBlur={e => { const autor = e.target.innerText; setNewPost(p => ({ ...p, autor: autor })) }}>{newPost.autor}</h5>

                                    <img className="capa-post" src={newPost.srcCapaPost} alt="" />
                                    <input type="file" onChange={handleImgInputChange} style={{ margin: '1rem auto', display: 'block' }} />
                                    <div id="editorNewPost" style={{textAlign:'justify'}}></div>
                                </div>
                            </div>
                        }
                        {isAdmin() && addPostagem &&
                            <div className="nav-item" style={{ margin: '2vw' }}
                                onClick={cadastrarPost}>
                                <a className="nav-link" style={{ textAlign: 'center' }}>+<br />Adicionar noticia</a>
                            </div>}
                        {post && post.createdDate &&
                            <Fade bottom cascade duration={1000}>
                                <div className="noticia-post">
                                    <div className="noticia-post-header">
                                        <div className="data-post">{new Date(post.createdDate).getDate()} <br /> {new Date(post.createdDate).toLocaleString('pt', { month: 'long' }).substr(0, 3).toUpperCase()}</div>
                                        <h2 className="noticia-title">{post.titulo}</h2>
                                    </div>
                                    <h5 className="noticia-por-user" style={{ display: 'inline-block' }}>POR &nbsp;</h5><h5 className="noticia-por-user" style={{ display: 'inline-block' }}>{post.autor}</h5>
                                    <img className="capa-post" src={post.srcCapaPost} alt="" />

                                    {post.blocks.map(block => {
                                        switch (block.type) {
                                            case 'header':
                                                return <h2 dangerouslySetInnerHTML={{ __html: block.data.text }}></h2>
                                            case 'paragraph':
                                                return <p dangerouslySetInnerHTML={{ __html: block.data.text }} className="noticia-text"></p>
                                            case 'link':
                                                return <a dangerouslySetInnerHTML={{ __html: block.data.text }}></a>
                                            case 'image':
                                                return <img src={block.data.url}></img>
                                            case 'list':
                                                return <ul style={{ listStyle: block.data.style == 'ordered' ? 'decimal' : '' }}>{block.data.items.map(i => <li>i</li>)}</ul>
                                            case 'raw':
                                                return <div dangerouslySetInnerHTML={{ __html: block.data.html }}></div>
                                        }
                                    })}
                                </div>
                            </Fade>}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default NoticiaPost;

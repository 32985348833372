import React, { Component, useState } from 'react';
import { Reveal } from 'react-reveal';
// import {Link} from 'react-router-dom';

function BlogSidebar() {
    return (
        <div className="col-md-3">

            <div className="">
                <Reveal effect="fadeInRight" duration={1500}>
                    <img className="blog-profile" src={require('../vanilsa-profile.png')} alt="" />
                    <div className="blog-sobre-profile">
                        <h2>Vanilsa Oliveira</h2>
                        <p>Entre em contato com
                        o Suporte ao cliente
                        para obter perguntas
                        sobre seus produtos,
                        treinamento ou
                                            eventos ....</p>
                        <h2>Cursos</h2>
                        <ul>
                            <li>Curso 1</li>
                            <li>Curso 2</li>
                            <li>Curso 3</li>
                        </ul>
                        <h2>Projetos</h2>
                        <ul>
                            <li>Projeto 1</li>
                            <li>Projeto 2</li>
                            <li>Projeto 3</li>
                        </ul>
                    </div>
                </Reveal>
            </div>
        </div>
    )
}
export default BlogSidebar;

import React, { useState, useEffect } from 'react';
import socketIOClient from "socket.io-client";
import { Launcher } from 'react-chat-window'
import {baseURL} from '../services/api';
function Chat(props) {

    let boasVindas=false;
    let timeout = 250;
    const [websocket,setWebsocket] = useState(null)
    const [conn,setConn] = useState(false)
    const connect = () => {
        const socket = socketIOClient(baseURL);
        var connectInterval;
        socket.on('connect', () => {
            if(!boasVindas){
                setTimeout(()=>_sendMessage("Olá! Teste"),3000)
                boasVindas=true
            }
            console.log("connected websocket main component");
            
            setConn(true)
            setWebsocket(socket)
        });
  
        socket.on('message' , msg => {
            if(msg.message){
                _sendMessage(msg.message)
            }
            console.log(msg)
        })
        socket.on('chatId' , chatId => {
            setChatState(s=>({...s,chatId:chatId}))
        })
        socket.on('disconnect' , e => {
            setConn(false)

        });
  
        socket.on('reconnect' , e => {
            _sendMessage('Conexão reestabelecida')
        });
        socket.on('error', err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );
  
            socket.close();
        });
    };
  
    // const check = () => {
    //     if (!websocket || websocket.readyState == WebSocket.CLOSED) connect(); 
    // };
    const [chatState, setChatState] = useState({
        messageList: [],
        newMessagesCount: 0,
        isOpen: false,
        createdDate:new Date()
    })
    const _onMessageWasSent = function (message) {
        message.createdDate=new Date()
        websocket.emit('chatMessage', {...chatState,
            messageList: [...chatState.messageList, message],
            message
        }
        )
        setChatState(s => ({...s,
            messageList: [...s.messageList, message]
        }));
    }

    const _sendMessage = (text) => {
        if (text.length > 0) {
            setChatState(s => ({
                ...s,
                newMessagesCount: s.isOpen ? s.newMessagesCount : s.newMessagesCount + 1,
                messageList: [...s.messageList, {
                    author: 'them',
                    type: 'text',
                    data: { text },
                    createdDate:new Date()
                }]
            }));
        }
    }

    const _handleClick = () => {
        setChatState(s => ({
            ...s,
            isOpen: !chatState.isOpen,
            newMessagesCount: 0
        }));
    }
     useEffect(()=>{
         connect()
     },[])
    return (conn&&
    <Launcher 
        agentProfile={{
            teamName: 'Contato Vanilsa Oliveira',
            imageUrl: require('../simbolo-vanilsa.png')
        }}
        onMessageWasSent={_onMessageWasSent}
        messageList={chatState.messageList}
        newMessagesCount={chatState.newMessagesCount}
        handleClick={_handleClick}
        isOpen={chatState.isOpen}
    />);
}
export default Chat
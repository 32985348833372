import React, { useState, useEffect } from 'react';
// import {Link} from 'react-router-dom';
import { Fade, Reveal } from 'react-reveal/';
import { isAdmin } from '../services/auth';
import api from '../services/api';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function Contato(props) {
    const [contatoFormData, setContatoFormData] = useState({
        nome:'',
        email:'',
        assunto:'',
        mensagem:''
    })
    // !editor && addConteudo && isAdmin() && (editor = new EditorJS({
    //     holderId: 'editorNewConteudo',
    //     ...editorTools,
    //     minHeight: 0,
    //     data: {
    //         blocks: [
    //             {
    //                 "type": "paragraph",
    //                 "data": {
    //                     "text": "Texto da Conteudo"
    //                 }
    //             }]
    //     },
    //     onChange: () => { editor.save().then(d => { setNewConteudo(p => ({ ...p, blocks: d.blocks })); console.log(d.blocks) }) }
    // }))
    // function cadastrarConteudo() {
    //     api.post('/conteudos/criar', newConteudo).then(response => {
    //         console.log(response)
    //         if (response.status == 'success') {
    //             setAddConteudo(false)
    //         }
    //         //setConteudos(response.data)
    //     })
    // }
    return (
        <section id="contato">
                <div className="row contato-container">
                    <div className="col-md-6 ">
                        <h2>Entre em contato</h2>
                        <div className="contato-redes">
                            <img src={require('../facebook-icon.png')}/>/90DIASNOFOCO
                            <img src={require('../email-icon.png')}/>contato@vanilsaoliveira.com
                            <img src={require('../telefone-icon.png')}/>(48) 99985-2349
                        </div>
                        <br/>
                    </div>
                    <div className="col-md-6 ">
                        <form>
                            <input type="text" className="input-text" placeholder="Nome"/>
                            <input type="text" className="input-text" placeholder="E-mail"/>
                            <input type="text" className="input-text" placeholder="Assunto"/>
                            <textarea rows="4" className="input-text" placeholder="Mensagem" >
                            
                            </textarea>
                            <button type="submit" className="input-button" style={{width:'auto',float:'left'}}>ENVIAR</button>
                        </form>

                    </div>
                </div>
        </section>
    )
}
export default Contato;
